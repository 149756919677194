import React, { FC, useState, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Logo, LogoHorizontal } from "../svg";
import LinkTo from "../linkTo/LinkTo";
import Container from "../container/Container";
import { useBackgroundColor } from "../../utils";
import { Button } from "../button";
import { Padlock } from "../icon/icons/Padlock";
import { Close, Menu } from "../icon";
import { Navigation, NavItemProps } from "../navigation/Navigation";
import { LinkType } from "../../types";
import SitewideBanner, { SitewideBannerProps } from "../sitewideBanner/SitewideBanner";

// Interface

export interface HeaderProps {
  navItems?: NavItemProps[];
  ctaButton?: LinkType;
  bannerSettings?: SitewideBannerProps;
}

interface StyledOverlayProps {
  overlayActive: boolean;
}

// Styles

const StyledHeader = styled.header`
  ${({ theme: { space, layers, media } }) => css`
    z-index: ${layers.header};
    position: fixed;
    top: 0;
    width: 100%;

    @media (min-width: 992px) and (max-height: 800px) {
      position: relative;
    }

    @media (min-width: 992px) and (min-height: 800px) {
      position: sticky;
      top: 0;
    }
  `};
`;

const StyledHeaderWrapper = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    ${useBackgroundColor("darkBlue")}
    padding-top: ${space.small};
    padding-bottom: ${space.small};
    height: 96px;

    @media (min-width: 992px) and (max-height: 800px) {
      position: relative;
      height: auto;
    }

    @media (min-width: 992px) and (min-height: 800px) {
      position: sticky;
      top: 0;
      height: auto;
    }
  `};
`;

const StyledContainer = styled(Container)`
  ${({ theme: { space, colors, media } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { space, colors, media } }) => css`
    flex-shrink: 0;
    svg {
      width: auto;
      height: 48px;
      flex-shrink: 0;

      &:last-of-type {
        display: none;
      }
    }

    @media (min-width: ${media.large}) {
      svg {
        width: auto;
        height: 52px;

        &:last-of-type {
          display: block;
        }

        &:first-of-type {
          display: none;
        }
      }
    }
  `};
`;

const StyledActions = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    display: flex;
    gap: ${space.xxxSmall};
  `};
`;

const StyledMenuButton = styled(Button)`
  ${({ theme: { space, colors, media } }) => css`
    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const StyledOverlay = styled.span<StyledOverlayProps>`
  ${({ theme: { space, colors, media, layers }, overlayActive }) => css`
    display: none;
    @media (min-width: ${media.large}) {
      pointer-events: none;
      display: block;
      background: ${colors.darkBlue};
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 105px);
      z-index: ${layers.overlay};
      opacity: ${overlayActive ? 0.5 : 0}; /* Conditional opacity */
      transition: opacity 0.3s ease-in-out; /* Add transition property */
    }

    @media (max-height: 800px) and (min-width: ${media.large}) {
      height: 3000px;
    }
  `}
`;

// JSX

export const Header: FC<HeaderProps> = ({ navItems, ctaButton, bannerSettings }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [overlayActive, setOverlayActive] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const setHeaderHeight = () => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }
  };

  useEffect(() => {
    setHeaderHeight();
    window.addEventListener('resize', setHeaderHeight);
    return () => {
      window.removeEventListener('resize', setHeaderHeight);
    };
  }, []);

  return (
    <StyledHeader ref={headerRef}>
      {bannerSettings && <SitewideBanner {...bannerSettings} onDismissChanged={setHeaderHeight} />}
      <StyledHeaderWrapper>
        <StyledContainer width="extraWide">
          <StyledLogo to="/" title="Home" id="naviagtion-logo">
            <Logo />
            <LogoHorizontal />
          </StyledLogo>
          {navItems && navItems.length > 0 && (
            <Navigation
              items={navItems}
              isActive={isMenuOpen}
              ctaButton={ctaButton}
              setOverlayActive={setOverlayActive}
              setIsMenuOpen={setIsMenuOpen}
            />
          )}
          <StyledActions>
            {ctaButton && (
              <Button
                variant="lime"
                size="fluidSmallToMedium"
                iconLeft={<Padlock size="small" />}
                dataLayerEvent="header_cta_button_click"
                {...ctaButton}
              />
            )}
            {navItems && navItems.length > 0 && (
              <StyledMenuButton
                variant="neutralOutline"
                title="Open menu"
                size="fluidSmallToMedium"
                iconLeft={
                  isMenuOpen ? <Close size="small" /> : <Menu size="small" />
                }
                a11yTitle="Open menu"
                onClick={handleMenuClick}
                dataLayerEvent={`mobile_menu_toggled_click_${
                  isMenuOpen ? "close" : "open"
                }`}
              />
            )}
          </StyledActions>
        </StyledContainer>
        <StyledOverlay overlayActive={overlayActive} />
      </StyledHeaderWrapper>
    </StyledHeader>
  );
};

export default Header;
